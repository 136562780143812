import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';

import { TimetableApi } from 'api';
import {
  Loading,
  EmptyList,
  Message,
  ProductList,
  Layout,
  TgMain,
} from 'components';

export default function ProductsPage() {
  const navigate = useNavigate();

  const [productFields, setProductFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [timetable, setTimetable] = useState(null);
  const [error, setError] = useState(null);

  const getTimetable = async () => {
    setIsLoading(true);

    try {
      const data = await TimetableApi.getToday();
      setTimetable(data);
    } catch (e) {
      setError(e);
    }

    setIsLoading(false);
  };

  const onProductQuantityChange = (id, count) => {
    setProductFields({ ...productFields, [id]: count });
  };

  const onContinue = () => {
    navigate('/order', { state: { productFields } });
  };

  useEffect(() => {
    getTimetable();
  }, []);

  if (isLoading) {
    return <Loading text="Загрузка..." />;
  }

  if (error) {
    return <EmptyList text="Блюда отсутствуют." />;
  }

  if (!timetable || !timetable.menu || !timetable.menu.products) {
    return <EmptyList text="Блюда отсутствуют." />;
  }

  const sheduledAt = moment(timetable.sheduledAt);
  const text = (
    `Итак, ${sheduledAt.format('DD MMMM')} мы приготовим для вас следующие блюда. `
    + 'Обращаем ваше внимание, что заказ нужно будет оформить '
    + 'до 14:00.'
  );

  return (
    <Layout>
      <TgMain text="Оформить заказ" onClick={onContinue} escaped={false} />
      <Message text={text} />
      <ProductList
        products={timetable.menu.products || []}
        onQuantityChange={onProductQuantityChange}
      />
    </Layout>
  );
}
