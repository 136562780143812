import React from 'react';
import PropTypes from 'prop-types';

import { ProductType } from 'types';
import Product from './product';

import 'styles/components/product-list.css';

export default function ProductList({ products, onQuantityChange }) {
  return (
    <div className="product-list">
      {products.map((product) => (
        <div className="product-list__item" key={product.id}>
          <Product
            data={product}
            quantity={0}
            onQuantityChange={onQuantityChange}
          />
        </div>
      ))}
    </div>
  );
}

ProductList.defaultProps = {
  onQuantityChange: null,
};

ProductList.propTypes = {
  products: PropTypes.arrayOf(ProductType).isRequired,
  onQuantityChange: PropTypes.func,
};
