import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { TimetableApi } from 'api';
import {
  Loading,
  EmptyList,
  Message,
  ProductList,
  Layout,
} from 'components';
import { useSearchParams } from 'react-router-dom';

export default function FutureProductsPage() {
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [timetables, setTimetables] = useState([]);
  const [error, setError] = useState(null);

  const getTimetable = async (date) => {
    setIsLoading(true);

    try {
      const data = await TimetableApi.listByDate(date);
      setTimetables(data);
    } catch (e) {
      setError(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getTimetable(searchParams.get('date'));
  }, [searchParams]);

  if (isLoading) {
    return <Loading text="Загрузка..." />;
  }

  if (error) {
    return <EmptyList text="Блюда отсутствуют." />;
  }

  if (timetables.length === 0) {
    return <EmptyList text="Блюда отсутствуют." />;
  }

  const timetable = timetables[0];

  if (!timetable || !timetable.menu || !timetable.menu.products) {
    return <EmptyList text="Блюда отсутствуют." />;
  }

  const sheduledAt = moment(timetable.sheduledAt);
  const text = (
    `Итак, ${sheduledAt.format('DD MMMM')} мы приготовим для вас следующие блюда. `
  );

  return (
    <Layout>
      <Message text={text} />
      <ProductList
        products={timetable.menu.products || []}
      />
    </Layout>
  );
}
