import { api } from 'config/axios';

const DeliveryPeriodApi = {
  getList: async () => {
    const response = await api.request({
      url: '/delivery-periods/',
      method: 'GET',
    });

    return response.data;
  },
};

export default DeliveryPeriodApi;
