import React from 'react';

import Layout from 'components/layout';
import { Message } from 'components';

export default function ThanksPage() {
  const onClick = () => {
    window.Telegram.WebApp.close();
  };

  return (
    <Layout>
      <Message text="Спасибо за ваш заказ." />
      <button type="button" className="btn" onClick={onClick}>Закрыть</button>
    </Layout>
  );
}
