import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ProductType } from 'types';

import 'styles/components/product.css';
import { formatMoney } from 'utils/format';

function ProductFooter({ price, quantity, onQuantityChange }) {
  const [counter, setCounter] = useState(quantity);

  const handleChange = (count) => {
    onQuantityChange(count);
  };

  const increase = () => {
    const count = counter < 10 ? counter + 1 : 10;
    setCounter(count);
    handleChange(count);
  };

  const decrease = () => {
    const count = counter - 1;
    setCounter(count);
    handleChange(count);
  };

  if (counter === 0) {
    return (
      <button type="button" className="product__btn" onClick={increase}>{formatMoney(price)}</button>
    );
  }

  return (
    <div className="product__cart">
      <button type="button" className="product__btn product__btn--quantity" onClick={decrease}>-</button>
      <input type="text" className="product__quantity" readOnly value={counter} onChange={handleChange} />
      <button type="button" className="product__btn product__btn--quantity" onClick={increase}>+</button>
    </div>
  );
}

export default function Product({ data, quantity, onQuantityChange }) {
  const handleQuantityChange = (count) => {
    onQuantityChange(data.id, count);
  };

  return (
    <div className="product">
      <div className="product_preview">
        <img src={data.imagePreview} className="product__image" loading="lazy" alt="product" />
      </div>
      <div className="product__body">
        <h2 className="product__title">{data.title}</h2>
        <p className="product__desc">{data.description}</p>
      </div>
      {onQuantityChange && (
        <div className="product__footer">
          <ProductFooter
            price={data.price}
            quantity={quantity}
            onQuantityChange={handleQuantityChange}
          />
        </div>
      )}
    </div>
  );
}

ProductFooter.propTypes = {
  price: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
};

Product.propTypes = {
  data: ProductType.isRequired,
  quantity: PropTypes.number.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
};
