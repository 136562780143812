import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import {
  EmptyList,
  Layout,
  Loading,
  OrderForm,
  TgMain,
} from 'components';
import { DeliveryPeriodApi, OrderApi } from 'api';

export default function OrderPage() {
  const { WebApp } = window.Telegram;

  const navigate = useNavigate();
  const { state } = useLocation();
  const [orderFields, setOrderFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [deliveryPeriods, setDeliveryPeriods] = useState([]);
  const [error, setError] = useState(null);

  const getDeliveryPeriodsFromApi = async () => {
    setIsLoading(true);

    try {
      const data = await DeliveryPeriodApi.getList();
      setDeliveryPeriods(data);
    } catch (e) {
      setError(e);
    }

    setIsLoading(false);
  };

  const onFormChange = (key, value) => {
    setOrderFields({ ...orderFields, [key]: value });
  };

  const onSubmit = async () => {
    setIsLoading(true);

    const products = Object.entries(state.productFields);
    const items = products.map(([product, quantity]) => ({ product, quantity }));
    const data = { ...orderFields, items };

    if (data.deliveryPeriod === '') {
      delete data.deliveryPeriod;
    }

    try {
      const response = await OrderApi.create(data);
      navigate('/invoice', { state: { orderId: response.id } });
    } catch (e) {
      WebApp.showAlert('Проверьте правильность заполнения формы.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDeliveryPeriodsFromApi();
  }, []);

  if (isLoading) {
    return <Loading text="Загрузка..." />;
  }

  if (error) {
    return <EmptyList text="Не удалось загрузить страницу." />;
  }

  return (
    <Layout>
      <TgMain text="Продолжить" onClick={onSubmit} />
      <OrderForm
        periods={deliveryPeriods || []}
        data={orderFields}
        onChange={onFormChange}
      />
    </Layout>
  );
}
