import React, { useEffect, useState } from 'react';

import {
  Layout, Invoice, Loading, EmptyList,
  TgMain,
} from 'components';
import { OrderApi } from 'api';
import { useLocation, useNavigate } from 'react-router';

export default function InvoicePage() {
  const { WebApp } = window.Telegram;

  const navigate = useNavigate();
  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const [error, setError] = useState(null);

  const getOrderFromApi = async (id) => {
    setIsLoading(true);

    try {
      const data = await OrderApi.get(id);
      setOrder(data);
    } catch (e) {
      setError(e);
    }

    setIsLoading(false);
  };

  const onContinue = async () => {
    try {
      await OrderApi.place(order.id);
      navigate('/thanks');
    } catch (e) {
      WebApp.showAlert('Проверьте правильность заполнения формы.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOrderFromApi(state.orderId);
  }, []);

  if (isLoading) {
    return <Loading text="Загрузка..." />;
  }

  if (error) {
    return <Loading text={error} />;
  }

  if (order === null) {
    return <EmptyList text="Не удалось загрузить..." />;
  }

  return (
    <Layout>
      <TgMain text="Продолжить" onClick={onContinue} escaped={false} />
      <Invoice order={order} />
    </Layout>
  );
}
