import { api } from 'config/axios';

const TimetableApi = {
  getToday: async () => {
    const response = await api.request({
      url: '/timetables/today/',
      method: 'GET',
    });

    return response.data;
  },
  listByDate: async (date) => {
    const response = await api.request({
      url: `/timetables/?sheduled_at=${date}`,
      method: 'GET',
    });

    return response.data;
  },
};

export default TimetableApi;
