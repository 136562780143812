import React from 'react';
import PropTypes from 'prop-types';

import { DeliveryPeriodType } from 'types';
import Choices from './choices';

export default function OrderForm({ periods = [], data = {}, onChange }) {
  const periodItems = periods.map((d) => ({ value: d.id.toString(), label: d.title }));
  periodItems.push({ label: 'Самовывоз', value: '' });

  return (
    <form className="form">
      <label className="form__field" htmlFor="comment">
        <span className="form__label">Комментарий</span>
        <textarea
          rows={5}
          id="comment"
          className="form__control"
          placeholder="Оставьте комментарий к заказу (приборы, острота, точное время, нужно ли перезвонить...)."
          value={data.comment || ''}
          onChange={(e) => onChange('comment', e.target.value)}
        />
      </label>

      <div className="form__field">
        <span className="form__label">Выберите удобное время доставки</span>
        <Choices
          items={periodItems}
          value={data.deliveryPeriod || ''}
          onChange={(e) => onChange('deliveryPeriod', e.target.value)}
        />
      </div>

      <label className="form__field" htmlFor="first-name">
        <span className="form__label">Имя</span>
        <input
          type="text"
          rows={5}
          id="first-name"
          className="form__control"
          placeholder="Укажите Ваше имя"
          value={data.firstName || ''}
          onChange={(e) => onChange('firstName', e.target.value)}
          required
        />
      </label>

      <label className="form__field" htmlFor="first-name">
        <span className="form__label">Фамилия</span>
        <input
          type="text"
          rows={5}
          id="first-name"
          className="form__control"
          placeholder="Укажите Вашу фамилию"
          value={data.lastName || ''}
          onChange={(e) => onChange('lastName', e.target.value)}
          required
        />
      </label>

      <label className="form__field" htmlFor="first-name">
        <span className="form__label">E-mail</span>
        <input
          type="email"
          rows={5}
          id="email"
          className="form__control"
          placeholder="Укажите Ваш e-mail"
          value={data.email || ''}
          onChange={(e) => onChange('email', e.target.value)}
          required
        />
      </label>

    </form>
  );
}

OrderForm.propTypes = {
  periods: PropTypes.arrayOf(DeliveryPeriodType).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
};
