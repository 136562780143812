import React from 'react';
import PropTypes from 'prop-types';

import 'styles/components/message.css';

export default function Message({ text }) {
  return (
    <div className="message">{text}</div>
  );
}

Message.propTypes = {
  text: PropTypes.string.isRequired,
};
