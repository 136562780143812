import React from 'react';
import { Route, Routes as RouterRoutes, useLocation } from 'react-router';
import { AnimatePresence } from 'framer-motion';

import {
  ProductsPage, OrderPage, InvoicePage, ThanksPage, FutureProductsPage,
} from 'pages';

export default function Routes() {
  const location = useLocation();

  return (
    <AnimatePresence>
      <RouterRoutes location={location} key={location.pathname}>
        <Route path="" element={<ProductsPage />} />
        <Route path="/future" element={<FutureProductsPage />} />
        <Route path="/order" element={<OrderPage />} />
        <Route path="/invoice" element={<InvoicePage />} />
        <Route path="/thanks" element={<ThanksPage />} />
      </RouterRoutes>
    </AnimatePresence>
  );
}
